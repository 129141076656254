import React, { createContext, useContext, useState } from 'react';

const INITIAL_CONTEXT = {
  isConsentGiven: false
}
const AppContext = createContext(INITIAL_CONTEXT)
const useAppContext = () => useContext(AppContext)

export const ProvidersWrapper = ({children}) => {
  
  const [isConsentGiven, setIsConsentGiven] = useState(false);

  return(<AppContext.Provider value={{
    isConsentGiven,
    setIsConsentGiven
  }}>
    {children}
  </AppContext.Provider>);
}

export { useAppContext }