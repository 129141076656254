exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-categoria-index-js": () => import("./../../../src/pages/blog/categoria/index.js" /* webpackChunkName: "component---src-pages-blog-categoria-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-single-index-js": () => import("./../../../src/pages/blog/single/index.js" /* webpackChunkName: "component---src-pages-blog-single-index-js" */),
  "component---src-pages-busca-index-js": () => import("./../../../src/pages/busca/index.js" /* webpackChunkName: "component---src-pages-busca-index-js" */),
  "component---src-pages-contato-index-js": () => import("./../../../src/pages/contato/index.js" /* webpackChunkName: "component---src-pages-contato-index-js" */),
  "component---src-pages-faq-categoria-index-js": () => import("./../../../src/pages/faq/categoria/index.js" /* webpackChunkName: "component---src-pages-faq-categoria-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-faq-single-index-js": () => import("./../../../src/pages/faq/single/index.js" /* webpackChunkName: "component---src-pages-faq-single-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lgpd-index-js": () => import("./../../../src/pages/lgpd/index.js" /* webpackChunkName: "component---src-pages-lgpd-index-js" */),
  "component---src-pages-politica-de-privacidade-index-js": () => import("./../../../src/pages/politica-de-privacidade/index.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-index-js" */),
  "component---src-pages-precos-index-js": () => import("./../../../src/pages/precos/index.js" /* webpackChunkName: "component---src-pages-precos-index-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-segmentos-bet-index-js": () => import("./../../../src/pages/segmentos/bet/index.js" /* webpackChunkName: "component---src-pages-segmentos-bet-index-js" */),
  "component---src-pages-segmentos-crypto-index-js": () => import("./../../../src/pages/segmentos/crypto/index.js" /* webpackChunkName: "component---src-pages-segmentos-crypto-index-js" */),
  "component---src-pages-segmentos-e-commerce-index-js": () => import("./../../../src/pages/segmentos/e-commerce/index.js" /* webpackChunkName: "component---src-pages-segmentos-e-commerce-index-js" */),
  "component---src-pages-segmentos-financeiro-index-js": () => import("./../../../src/pages/segmentos/financeiro/index.js" /* webpackChunkName: "component---src-pages-segmentos-financeiro-index-js" */),
  "component---src-pages-segmentos-fintech-index-js": () => import("./../../../src/pages/segmentos/fintech/index.js" /* webpackChunkName: "component---src-pages-segmentos-fintech-index-js" */),
  "component---src-pages-segmentos-transporte-index-js": () => import("./../../../src/pages/segmentos/transporte/index.js" /* webpackChunkName: "component---src-pages-segmentos-transporte-index-js" */),
  "component---src-pages-sobre-nos-index-js": () => import("./../../../src/pages/sobre-nos/index.js" /* webpackChunkName: "component---src-pages-sobre-nos-index-js" */),
  "component---src-pages-termos-de-uso-index-js": () => import("./../../../src/pages/termos-de-uso/index.js" /* webpackChunkName: "component---src-pages-termos-de-uso-index-js" */)
}

