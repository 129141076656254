import React, { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import './Cookies.scss'
import { useAppContext } from "@components/AppContext";
const Cookies = () => {
  const { setIsConsentGiven } = useAppContext();

  useEffect(() => {
    const consent = localStorage.getItem("myAwesomeCookieConsent");
    if (consent === "accepted" || consent === "declined") {
      setIsConsentGiven(true);
    }
  }, []);

  const handleAcceptCookie = () => {
    setIsConsentGiven(true);
    localStorage.setItem("myAwesomeCookieConsent", "accepted");
  };

  const handleDeclineCookie = () => {
    setIsConsentGiven(false);
    localStorage.setItem("myAwesomeCookieConsent", "waiting");
    window.location.href = "/politica-de-privacidade";
  };

  return (
    <>
      <CookieConsent
        location="none"
        buttonText="Aceitar"
        declineButtonText={"Políticas de Privacidade"}
        cookieName="myAwesomeCookieName2"
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        setDeclineCookie={false}
        style={{
          background: "#109aee",
          position: "fixed",
          bottom: "20px",
          left: "20px",
          width: "350px",
          height: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          zIndex: 10000,
        }}
        declineButtonStyle={{
          backgroundColor: "transparent",
          color: "#FFF",
          fontSize: "13px",
          padding: "10px 19px",
          borderRadius: "5px",
          marginLeft: "10px" 
        }}
        buttonStyle={{backgroundColor:"#0c5c9f", color: "#FFF", fontSize: "13px", padding: "10px 19px" }}
        expires={150}
      >
        Uso de Cookies<br />
        <span style={{ fontSize: "13px" }}>
          Você aceita que usamos cookies para uma melhor experiência no site?
        </span>
      </CookieConsent>
    </>
  );
};

export default Cookies;
