// Core
import React from 'react';
import Cookies from '@components/Cookies'

// Import Swiper Styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/bundle';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';

// Libraries
import { QueryClient, QueryClientProvider } from 'react-query';
import { ProvidersWrapper } from '@components/AppContext';

const queryClient = new QueryClient();

function wrapElement({ element }) {

  return (
  <QueryClientProvider client={queryClient}>
    <ProvidersWrapper>
      <Cookies />
      {element}
    </ProvidersWrapper>
  </QueryClientProvider>
    )
}
export default wrapElement;
